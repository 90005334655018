import React from "react"
import styled from 'styled-components'
import {Container} from 'reactstrap'

import Layout from "components/Layout/Layout"
import HeaderCopy from "components/shared/HeaderCopy"

const Content = styled.div`
    text-align: center;
    margin: 2rem auto;
    max-width: 540px;
`

const EmailButton = styled.a`
    width: 198px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.yellow};
    transition: all 150ms ease-in-out;
    margin: 0 auto;
    
    &:hover {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.black};
        text-decoration: none;
    }
`


const FeedbackPage = () => {
    return (
        <Layout slug="feedback" title="Feedback">
            <HeaderCopy title="Feedback"/>

            <Container fluid={true}>
                <Container>
                    <Content>
                        <h2>Get in contact with us</h2>
                        <p>To help us to continue to develop in this area, we are always interested in receiving feedback about your experience of the Education Programme.</p>
                        <EmailButton href="mailto:nancy.shakerley@disney.com ?subject=The%20Lion%20King%20Education%20Programme%20Feedback">Email us</EmailButton>
                    </Content>
                </Container>
            </Container>
        </Layout>
    )
}

export default FeedbackPage